import React, {useContext} from 'react';

import Providers from '~src/components/Providers';
import {ContentView, Modals, PageTemplate} from '~src/components';
import {Collapse, Row, Col, Typography} from 'antd';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import Seo from '~src/components/Seo';
const {Panel} = Collapse;
const {Title, Text, Paragraph} = Typography;

interface IFaqProps extends IPage {}

const Faq: React.FC<IFaqProps> = ({location, navigate, path}) => {
  const {
    pages: {faq},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo title={faq.meta.title} />
      <PageTemplate breadcrumbs={[...faq.breadcrumbs, faq.header1]} breadcrumbsSource={faq.breadcrumbsSource}>
        <ContentView>
          <Row>
            <Col span={24}>
              <Title level={1}>{faq.header1}</Title>
              <Row gutter={[20, 30]}>
                <Col span={24}>
                  <Text>
                    Здесь мы собрали все наиболее популярные вопросы по расчетами и ответы на них, а также руководство
                    по использованию сервиса
                  </Text>
                </Col>
                <Col span={24}>
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Title level={2}>Справка по работе с калькулятором</Title>
                        </Col>
                        <Col span={24}>
                          <Collapse defaultActiveKey={1} accordion bordered={false}>
                            <Panel header="Калькулятор Тепловая защита зданий" key="1">
                              <Paragraph>
                                В самом начале работы с калькулятором, для проведения правильного расчета, необходимо
                                выполнить выбор параметров, определяющих основные климатические характеристики, как
                                внутри так и снаружи полмещения, и нормативные требования, предъявляемые к конструкции.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Место строительства
                              </Paragraph>
                              <Paragraph>
                                Россия - государство, занимающее огромную территорию. Климатические параметры в
                                различных точках страны существенно отличаются. Поэтому для проведения точного расчета
                                необходимо знать географическую точку, в которой будет строиться дом. Выбор этой точки
                                можно сделать в сворачиваемой панели, на которой указаны названия региона и населенного
                                пункта.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Помещение и конструкция
                              </Paragraph>
                              <Paragraph>
                                От вида помещения зависит его температурный и влажностный режим. Например на кухне
                                обычно влажность и температура выше, чем в жилом помещении, а в ванной, выше, чем на
                                кухне. От вида конструкции зависят как нормируемые требования, предъявляемые к ней, так
                                и ряд параметров, используемых при расчете. Выбор этих параметров осуществляется в
                                соответствующей сворачиваемой панели.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Темпрература и влажность
                              </Paragraph>
                              <Paragraph>
                                На вкладках с результатами расчетов в сворачиваемых панелях возможно изменение некоторых
                                климатических параметров:
                              </Paragraph>
                              <ol>
                                <li>
                                  На вкладке "Тепловая защита" можно изменить значения температуры и относительной
                                  влажности как внутри так и снаружи помещения. Кроме того можно выбрать средние
                                  температуру и влажность уличного воздуха, характерные для выбранного населенного
                                  пункта. При этом изменение этих параметров никоим образом не влияет на результаты
                                  расчета, а необходимо только для наглядности отображения на графике и в таблице
                                  изменения температур в конструкции для заданных Вами параметров. При этом появление на
                                  графике так называемой "Зоны конденсации" никоим образом не означает, что в
                                  конструкции возможны проблемы с недопустимым накоплением влаги.
                                </li>
                                <li>
                                  На вкладке "Влагонакопление" можно изменить значение температуры внутри помещения. При
                                  этом изменение этого параметра будет учтено в расчете. Установленная нормами
                                  температура для расчета накопления влаги - 20˚С. Но если Вы привыкли эксплуатировать
                                  жилье с другой температурой, то возможно изменение этого параметра. Но при этом, надо
                                  понимать, что значения температуры и относительной влжности - вещи взаимо связанные и,
                                  т.к. изменяется только температура, то расчет может проводиться не с совсем
                                  корректными данными по влажности внутри помещения.
                                </li>
                              </ol>
                              <Paragraph strong={true} mark={true}>
                                Построение конструкции
                              </Paragraph>
                              <Paragraph>
                                Выбор слоев конструкции, их типов и толщин, материалов, из которых состоит каждый слой,
                                производится в сворачиваемой панели "Слои конструкции"
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Результаты расчет
                              </Paragraph>
                              <Paragraph>
                                Расчет в этом разделе оценивает исключительно теплозащитные характеристики конструкции.
                                График температуры точки росы и зона конденсации на рисунке приведены исключительно в
                                информационных целях. Оценка возможности образования конденсата и допустимости
                                количества влаги в конструкции согласно строительным нормам и правилам должна делаться
                                при расчете накопления влаги. В результате расчета тепловой защиты вычисляется значение
                                сопротивления теплопередаче ограждающей конструкции (далее R). Оно и является
                                показателем тепловой защиты спроектированного Вами участка ограждающей конструкции. Для
                                того, чтобы оценить, достаточно это значение, в таблице с результатами, сразу за
                                вышеуказанным сопротивлением теплопередаче, приведены три нормируемых значения. Все они
                                расчитываются исходя из климатических условий той местности, в которой строится или
                                будет строиться дом.
                              </Paragraph>
                              <ol>
                                <li>
                                  Требуемое сопротивление теплопередаче согласно санитарно-гигиеническим требованиям.
                                  Если R меньше этого значения, то лучше в помещении с такой тепловой защитой не жить.
                                  Возможно образование конденсата на стене, инея, появление грибков и плесени.
                                </li>
                                <li>
                                  Нормируемое значение требуемого сопротивления теплопередаче согласно поэлементным
                                  требованиям. Это значение базового поэлементного требуемого сопротивления
                                  теплопередаче умноженного на понижающий коэффициент. Величина этого коэффициента
                                  зависит от типа конструкции. Это значение является допустимым в том случае, если
                                  здание целиком удовлетворяет требованиям к удельному расходу тепловой энергии на
                                  отопление. Расшифровку этих требований можно найти в нормативной документации. А
                                  упрощенно оно означает, что оно допустимо, если: - Вся оболочка дома имеет достаточную
                                  теплозащиту. Включая стены, потолки, полы, окна и двери. - Все инженерные системы
                                  являются экономными (отопление, ветиляция, водостабжение и т.п.) и излишне не
                                  расходуют энергию. Т.е. можно выбрать, где Вам экономически выгоднее потратить свои
                                  деньги. Например, если поставить энергоэффективыне окна и двери, хорошо утеплить
                                  перекрытия над подвалом и потолок, то нет нужды строить слишком толстые или излишне
                                  утепленные стены, т.к. денежные средства на увеличение их теплозащиты в обозримом
                                  будущем не окупятся.
                                </li>
                                <li>
                                  Поэлементные требования требуемого сопротивление теплопередаче. Достижение этого
                                  значения тепловой защиты говрит о том, что конструкция в любом случае
                                  энегроэффективна. И дальнейшее увеличение уровня тепловой защиты оправдано если только
                                  энергоноситель для системы отопления в Вашем случае чрезвычайно дорог или Вы сами
                                  желаете построить то, что называется "Пассивный дом". Только не стоит забывать, что
                                  общая энегроэффективность дома определяется не одной конструкцией (например, стенами),
                                  а всей внешней оболочкой дома, а так же энергоэффективностью всех инженерных систем
                                  здания. Если у Вас будет оболочка "пассивного дома", но при этом половина выработанной
                                  ссистемой отоплени тепловой энергии будет вылетать в вентиляцию, то проку от такого
                                  утепления будет очень мало.
                                </li>
                              </ol>
                              <Paragraph> Какой уровень тепловой защиты выбрать - решать Вам.</Paragraph>
                            </Panel>
                            <Panel header="Калькулятор Расчет количества материалов" key="2">
                              <Paragraph>
                                Для начала работы требуется выбрать из списка необходимый раздел, находящийся в
                                центральной части экрана. Для каждой калькуляции необходимо вводить требуемые показатели
                                и данные. Каждый расчет снабжен дополнительными справочными материалами, а также
                                иллюстративно подкреплен удобным чертежом.
                              </Paragraph>
                              <Paragraph>
                                Некоторые расчеты позволяют вычислить и экономическую составляющую предполагаемых работ,
                                к примеру, указав стоимость одной единицы материала, калькулятор сосчитает общую
                                стоимость всего необходимого количества. Расчет дополнительных показателей производится
                                при отмеченной галочке напротив интересующего пункта. Результат подсчета моментально
                                появляется на экране после нажатия клавиши «Рассчитать».
                              </Paragraph>
                              <Paragraph>
                                Все без исключения подобные калькуляторы подразумевают небольшую погрешность. В связи с
                                этим, предварительные подсчеты необходимо согласовывать со специалистами в данной
                                области или же проверять ими уже проведенные расчеты.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Периметр строения
                              </Paragraph>
                              <Paragraph> Общая длина всех стен учтенных в расчетах.</Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Общая площадь кладки
                              </Paragraph>
                              <Paragraph>
                                Площадь внешней стороны стен. Соответствует площади необходимого утеплителя, если такой
                                предусмотрен проектом.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Толщина стены
                              </Paragraph>
                              <Paragraph>
                                Толщина готовой стены с учетом толщины растворного шва. Может незначительно отличаться
                                от конечного результата в зависимости от вида кладки.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Количество
                              </Paragraph>
                              <Paragraph>
                                Общее количество кирпичей необходимых для постройки стен по заданным параметрам
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Общий вес материала
                              </Paragraph>
                              <Paragraph>
                                Вес кирпичей без учета раствора и кладочной сетки. Так же как и общий объем, необходим
                                для выбора варианта доставки.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Кол-во раствора на всю кладку
                              </Paragraph>
                              <Paragraph>
                                Объем строительного раствора, необходимый для кладки всех кирпичей. Объемный вес
                                раствора может отличаться в зависимости от соотношения компонентов и введенных добавок.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Количество гибких связей
                              </Paragraph>
                              <Paragraph>
                                Необходимы для крепления облицовочного слоя к основным несущим стенам. Длина гибких
                                связей зависит от общей толщины стены с учетом утеплителя.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Кол-во рядов в кладке с учетом швов
                              </Paragraph>
                              <Paragraph>
                                Зависит от высоты стен, размеров применяемого материала и толщины кладочного раствора.
                                Без учета фронтонов.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Кол-во кладочной сетки
                              </Paragraph>
                              <Paragraph>
                                Необходимое количество кладочной сетки в метрах. Применяется для армирования кладки,
                                увеличивая монолитность и общую прочность конструкции. Обратите внимание на количество
                                армированных рядов, по умолчанию указано армирование каждого третьего ряда.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Примерный вес готовых стен
                              </Paragraph>
                              <Paragraph>
                                Вес готовых стен с учетом всех кирпичей, раствора и кладочной сетки, но без учета веса
                                утеплителя и облицовки.
                              </Paragraph>
                              <Paragraph strong={true} mark={true}>
                                Нагрузка на фундамент от стен
                              </Paragraph>
                              <Paragraph>
                                Нагрузка без учета веса кровли и перекрытий. Данный параметр необходим для выбора
                                прочностных характеристик фундамента.
                              </Paragraph>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Title level={2}>Справочная информация</Title>
                        </Col>
                        <Col span={24}>
                          <Collapse defaultActiveKey={1} accordion bordered={false}>
                            <Panel header="Теплотехнические материалы" key="1">
                              <Paragraph>
                                Один из первых вопросов, встающих при строительстве дома - выбор материала стен.
                                Параметров для сравнения материалов достаточно много, один из важнейших -
                                энергосберегающие характеристики будущего дома.
                              </Paragraph>
                              <Paragraph>
                                Основной нормативный документ, регламентирующий параметры теплопотерь дома - СНиП
                                23-02-2003 "Тепловая защита зданий". В этом документе, в частности, прописаны требования
                                к сопротивлению теплопередаче стены.
                              </Paragraph>
                              <Paragraph>
                                Этот параметр рассчитывается по следующей формуле: R req = S материала (м) / коэффициент
                                теплопроводности. S – толщина материала, м. Коэффициент теплопроводности для каждого
                                материала индивидуален. R req – сопротивление теплопередачи стены. Оно зависит от
                                региона проживания.
                              </Paragraph>
                              <Paragraph>
                                Нормативный показатель на сопротивление теплопередаче стены для московского региона
                                составляет R=3,16 м2°C/Вт.
                              </Paragraph>
                              <Paragraph>
                                Мы подобрали несколько типовых решений конструкций стен для строительства сезонных и
                                круглогодичных домов. Конструкции стен, предназначенных для домов постоянного
                                проживания, расчитаны с точки зрения минимизации теплопотерь и отвечают требованиям СНиП
                                23-02-2003 по тепловой защите жилых зданий.
                              </Paragraph>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default Faq;
