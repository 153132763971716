import React, {Fragment, useEffect} from 'react';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

interface IAppProviderProps {}

const AppProvider: React.FC<IAppProviderProps> = ({children}) => {
  const {dispatch} = useStoreon<IState, IEvents>('app');
  useEffect(() => {
    dispatch('app/init');
  }, []);
  return <Fragment>{children}</Fragment>;
};

export default AppProvider;
