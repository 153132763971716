import React from 'react';
import {LocaleContext} from '~src/context';
import locales from '~src/locales';
import {ConfigProvider} from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import GlobalStyle from '~src/theme/global-style';
import {ErrorBoundary} from '~src/components/molecules';

import moment from 'moment';
import 'moment/locale/ru';
import AppProvider from '~src/components/AppProvider';
moment.locale('ru');

interface IProvidersProps {}

const Providers: React.FC<IProvidersProps> = props => {
  return (
    <ErrorBoundary>
      <AppProvider>
        <LocaleContext.Provider value={locales}>
          <ConfigProvider locale={ruRu}>
            <GlobalStyle />
            {props.children}
          </ConfigProvider>
        </LocaleContext.Provider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default Providers;
